//
// Question-Chat
//

@use 'sass:map';

.question-chat-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 20px;
}
.question-chat .screen .nexus5 {
	text-align: left;
	width: 100% !important;
}

.screen-container {
	height: 100%;
	width: 100%;
}

/* Status Bar */

.status-bar {
	width: 100%;
	height: 25px;
	background: #004e45;
	color: #fff;
	font-size: 14px;
	padding: 0 8px;
	@include dark-mode {
		background: var(--bs-modal-bg) !important;
	}
}

.status-bar:after {
	content: '';
	display: table;
	clear: both;
}

.status-bar div {
	float: right;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 0 0 8px;
	font-weight: 600;
}

/* Chat */

.chat {
	height: calc(100% - 69px);
	width: 100%;
}

.chat-container {
	height: 100%;
	width: 100%;
}

/* User Bar */

.user-bar {
	height: 55px;
	width: 100%;
	background: #005e54;
	color: #fff;
	// padding: 0 8px;
	font-size: 24px;
	// position: relative;
	@include dark-mode {
		background: var(--bs-modal-bg) !important;
	}
}

.user-bar:after {
	content: '';
	display: table;
	clear: both;
}
.user-bar.remove-after:after {
	content: unset;
}
.user-bar div {
	// float: left;
	// transform: translateY(-50%);
	// position: relative;
	// top: 50%;
}
.user-bar {
	// float: right;
	margin: 0 0 0 0;
}
.user-bar {
	// display: block;
}

.user-bar .avatar {
	// margin: 0 0 0 5px;
	width: 60px;
	// height: 40px;
}

.user-bar .avatar img {
	// border-radius: 50%;
	// box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
	// display: block;
	// width: 100%;
}

.user-bar .name {
	font-size: 17px;
	font-weight: 600;
	text-overflow: ellipsis;
	letter-spacing: 0.3px;
	// margin: 0 0 0 8px;
	overflow: hidden;
	white-space: nowrap;
	width: 110px;
}

.user-bar .status {
	// display: block;
	// font-size: 13px;
	// font-weight: 400;
	// letter-spacing: 0;
}

/* Conversation */

.conversation {
	height: 200px;
	@media (min-width: 768px) {
		height: 400px;
	}
	width: 100%;
	position: relative;
	background: #efe7dd
		url('https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg')
		repeat;
	z-index: 0;
	@include dark-mode {
		background: #000 !important;
	}
	position: relative;
	transform: translate(0);
	overflow: hidden;
}

.conversation ::-webkit-scrollbar {
	transition: all 0.5s;
	width: 5px;
	height: 1px;
	z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
	background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
	background: #b3ada7;
	@include dark-mode {
		background: #e57607;
	}
}

.conversation .conversation-container {
	height: 200px;
	@media (min-width: 768px) {
		height: 400px;
	}
	box-shadow: inset 0 10px 10px -10px #000000;
	overflow-x: hidden;
	padding: 0 16px;
	padding-bottom: 60px;
	margin-bottom: 5px;
	overflow-y: scroll;
}

.conversation .conversation-container:after {
	content: '';
	display: table;
	clear: both;
}

/* Messages */

.message {
	color: #000;
	clear: both;
	line-height: 18px;
	font-size: 15px;
	padding: 8px;
	position: relative;
	margin: 8px 0;
	max-width: 85%;
	word-wrap: break-word;
	z-index: -1;
}

.message:after {
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
}

.metadata {
	display: inline-block;
	float: right;
	padding: 0 0 0 7px;
	position: relative;
	bottom: -4px;
}

.metadata {
	color: rgba(0, 0, 0, 0.45);
	font-size: 11px;
	display: inline-block;
}

.metadata .tick {
	display: inline-block;
	margin-left: 2px;
	position: relative;
	top: 4px;
	height: 16px;
	width: 16px;
}

.metadata .tick svg {
	position: absolute;
	transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(800px) rotateY(180deg);
	transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(800px) rotateY(0deg);
	transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
	-webkit-transform: perspective(800px) rotateY(0);
	transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
	-webkit-transform: perspective(800px) rotateY(-179.9deg);
	transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
	margin: 16px 0 8px;
}

.message.received {
	background: #fff;
	border-radius: 0px 5px 5px 5px;
	float: left;
	@include dark-mode {
		background: #343a40 !important;
	}
}

.message.received .metadata {
	padding: 0 0 0 16px;
}

.message.received:after {
	border-width: 0px 10px 10px 0;
	border-color: transparent #fff transparent transparent;
	top: 0;
	left: -10px;
	@include dark-mode {
		border-color: transparent #343a40 transparent transparent;
	}
}

.message.sent {
	background: #e1ffc7;
	border-radius: 5px 0px 5px 5px;
	float: right;
	@include dark-mode {
		background: #343a40 !important;
	}
}

.message.sent:after {
	border-width: 0px 0 10px 10px;
	border-color: transparent transparent transparent #e1ffc7;
	top: 0;
	right: -10px;
	@include dark-mode {
		border-color: transparent transparent transparent #343a40;
	}
}

/* Compose */

.conversation-compose {
	z-index: 2;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: hidden;
	background: #fff;
	height: 50px;
	width: 95%;
	margin: 0 auto 10px auto;
	border-radius: 5px;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.15), 0 2px 8px rgba(13, 18, 22, 0.15);
	@include dark-mode {
		background: #343a40;
	}
}

.conversation-compose .plus-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	flex: 0 0 auto;
	@include dark-mode {
		background: #343a40 !important;
	}
}
.plus-icon {
	font-size: large;
	padding: 10px;
}

.input-msg {
	border: 0;
	flex: 1 1 auto;
	font-size: 16px;
	outline: none;
	min-width: 50px;
	// margin-bottom: 10px;
}

.conversation-compose .loading {
	flex: 0 0 auto;
	border-radius: 0 0 5px 0;
	text-align: center;
	position: relative;
	width: 48px;
}

.conversation-compose .loading:after {
	border-width: 0px 0 10px 10px;
	border-color: transparent transparent transparent #fff;
	border-style: solid;
	position: absolute;
	width: 0;
	height: 0;
	content: '';
	top: 0;
	right: -10px;
}

.conversation-compose .loading i {
	display: block;
	color: #7d8488;
	font-size: 24px;
	transform: translate(-50%, -50%);
	position: relative;
	top: 50%;
	left: 50%;
}
.conversation-compose .send {
	background: transparent;
	border: 0;
	cursor: pointer;
	flex: 0 0 auto;
	margin-left: 8px;
	margin-right: 8px;
	padding: 0;
	position: relative;
	outline: none;
}
.question-popup-container {
	@include dark-mode {
		background-color: #343a40 !important;
		border: '.5px solid #000';
	}
}
.question-popup-files {
	font-size: 18px !important;
	border-radius: 8px;
	@include dark-mode {
		background-color: #343a40 !important;
	}
}
.question-popup {
	background: #f4f4f4;
	align-items: center;
	border-radius: 8px;
	padding: 5px;
	@include dark-mode {
		background-color: #343a40 !important;
	}
}
.question-popup:hover {
	background: #ccd2d7;
	cursor: pointer;
	@include dark-mode {
		background: #59646f !important;
	}
}
.send .circle {
	background: #008a7c;
	border-radius: 50%;
	color: #fff;
	position: relative;
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.send .circle {
	font-size: 24px;
	margin-left: 5px;
}

/* Small Screens */

@media (max-width: 768px) {
	.question-chat .nexus5 {
		border-radius: 0;
		flex: none;
		padding: 0;
		max-width: none;
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	.question-chat > .screen .chat {
		visibility: visible;
	}

	.question-chat {
		// visibility: hidden;
	}

	.question-chat .status-bar {
		// display: none;
	}

	.screen-container {
		// position: absolute;
		// top: 0;
		// left: 0;
		// right: 0;
		// bottom: 0;
	}

	.conversation {
		// height: calc(100vh - 55px);
	}
	.conversation .conversation-container {
		// height: calc(100vh - 120px);
		// width: '100%';
	}
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
