.module-optios-container {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 16px;
	min-height: 250px;
	@media (min-width: 768px) {
		min-height: 200px;
	}
}
.module-options-header {
	padding: 14px 0;
}
.module-options-body {
	.col-2 {
		flex-shrink: 0;
	}
}
.scroll-container {
	padding: 0;
}
.sub-type-card {
	min-height: 144px;
	width: 180px;
	flex-shrink: 0;
}
.module-item {
	flex-shrink: 0;
}
.modules-list {
	height: 100%;
	// overflow-y: hidden;
}
// .module-options-container {
// 	max-width: 992px;
// }
.search-bar {
	div:nth-child(1) {
		border: 1px solid var(--bs-grey-card-border-color);
		padding: 4px 8px;
		border-radius: 10px;
	}
}
.active-pointer {
	background-color: var(--bs-active-bg);
	bottom: -7px;
	height: 16px;
	left: 50%;
	position: absolute;
	transition: background-color 0.15s ease-in-out;
	width: 16px;
	border-bottom-left-radius: 4px;
	transform: translateX(calc(2px - 50%)) rotate(135deg);
}
.workflow-type-container {
	.icon-placeholder {
		background-color: var(--bs-icon-bg);
		border-radius: 50%;
		border: 1px solid var(--bs-grey-card-border-color);
		box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.15), 0 2px 8px rgba(13, 18, 22, 0.15);
		// color: var(--bs-main-node-color);
		height: 48px;
		margin-bottom: 8px;
		width: 48px;
	}
	.type-name {
		font-weight: 600;
	}
}

//Form option button
.custom-file-input {
	.option-toggle-btn {
		top: 80px;
		right: 23px;
	}
	.input-option-btn-wrapper {
		height: 150px;
		top: -30px;
	}
}
.quill-text-editor-container {
	.ql-container {
		min-height: 100px;
	}
}

.audio-recorder,
.input-option-btn {
	background-color: #fff !important;
	box-shadow: 0 2px 5px #bebebe;
	border-radius: 20px;
	box-sizing: border-box;
	color: #000;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	transition: all 0.2s ease-in;
	-webkit-tap-highlight-color: transparent;
}

.input-option-btn:hover{
	color: #2c2c2c;
}

.input-option-btn {
	justify-content: center;
}
.option-toggle-btn {
	position: absolute;
	right: 23px;
	top: 100px;
}
.input-option-btn-wrapper {
	transition: all 0.2s;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	overflow: hidden;
	height: 208px;
	transform-origin: center bottom;
	top: -5px;
	right: 23px;
}
.input-option-btn-wrapper.show {
	transform: scale(1);
}
.input-option-btn-wrapper.hide {
	transform: scale(0);
}

.parent-sheet-content.show-scroll {
	@media (min-width: 992px) {
		overflow-y: scroll;
	}
}

.collaborator-form-container {
	.input-field-container {
	}
	.list-container {
		height: 80vh;
		overflow: scroll;
		@media (max-width: 992px) {
			height: 50vh;
		}
	}
	.list-card {
		min-height: 250px;
		width: 49%;
		overflow: hidden;
	}
}
