.filter-accordion {
	.text-black {
		color: #000;
		@include dark-mode {
			color: #fff !important;
		}
	}
}

.MuiPaper-root {
	svg {
		@include dark-mode {
			color: #fff;
		}
	}
}
.css-1o7vfxb-MuiPaper-root {
	.MuiButton-textInherit {
		@include dark-mode {
			color: #fff;
		}
	}
	@include dark-mode {
		background-color: #1f2128;
	}
}
.css-17lwjjt {
	.MuiButton-textInherit {
		@include dark-mode {
			color: #fff;
		}
	}
	@include dark-mode {
		background-color: #1f2128;
	}
}
.css-1rlrl7 {
	.rs__cell {
		@include dark-mode {
			background-color: #1f2128;

			color: #fff;
			border-color: #fff;
		}
	}
}
.css-l3bzu8 {
	.rs__cell {
		@include dark-mode {
			background-color: #1f2128;
			color: #fff !important;
			border-color: #fff;
		}
		.css-1gqyz35-MuiTypography-root {
			color: #fff !important;
		}
	}
}
.css-12aktbw {
	.rs__cell {
		@include dark-mode {
			background-color: #1f2128;
			color: #fff;
			border-color: #fff;
		}
	}
}

.css-nbfzex {
	@include dark-mode {
		color: #fff;
	}
}

.rs__view_navigator {
	display: none !important;
}
