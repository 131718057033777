.filmstrip-scroll {
	height: 100vh;
	width: 230px;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	margin: 10px 0;
	background-color: $white;
	@include dark-mode {
		background-color: #000;
	}
}

.filmstrip {
	background-color: $white;
	@include dark-mode {
		background-color: $black-100;
	}
	padding: 0;
	position: relative;
	white-space: nowrap;
	min-width: 220px;
	display: flex;
	justify-content: end;
	gap: 10px;
	padding: 5px 20px;
}
.filmstrip-thumbnail-pagenumber {
	color: #000;
	@include dark-mode {
		color: #fff !important;
	}
}
.filmstrip-thumbnail {
	width: 152px;
	height: 88px;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;

	.text-black {
		color: #000;
		@include dark-mode {
			color: #fff !important;
		}
	}
	.filmstrip-thumbnail-inner {
		width: 148px;
		height: 84px;
		border: 1px solid var(--bs-grey-card-border-color);
		border-radius: 10px;
		overflow: hidden;
	}
	.filmstrip-thumbnail-drag-overlay {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.filmstrip-thumbnail.active {
	border-color: rgb(11, 87, 208);
	border-width: 2px;
	border-style: solid;
}

.slides-view {
	width: calc(100% - 230px);
	height: 100vh;
	overflow-y: scroll;
	background-color: $white;
	@include dark-mode {
		background-color: $black-100;
	}
	.text-black {
		color: #000;
		@include dark-mode {
			color: #fff !important;
		}
	}
	.bg-light {
		.text-black {
			color: #000;
			@include dark-mode {
				color: #000 !important;
			}
		}
	}
}
