.top-search-container {
	padding: 10px 16px;
	border-radius: 40px;
	background-color: var(--bs-grey-card-bg);
	.btn-grey,
	.btn-dark {
		padding: 7px 13px;
		border-radius: 40px !important;
	}
}
.top-header-action-btn {
	.btn-dark {
		padding: 7px 13px;
		border-radius: 40px !important;
	}
}
.btn-dark.active-btn {
	border: 1px solid var(--bs-black-text);
	@include dark-mode {
		background-color: #fff;
		color: #000;
	}
}
.general-data-grid {
	display: grid;
	height: 250px;
	width: 100%;
	gap: 15px;
	grid-template-columns: 200px 400px repeat(3, 250px);
}
.grid-item {
	overflow: hidden;
	border-radius: 16px !important;
}
.card-top-section {
	height: 85%;
	// overflow: hidden;
}
.card-bottom-section {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	// overflow: hidden;
}
.products-data-row,
.images-data-row {
	.card-bottom-section {
		.dropdown {
			svg {
				fill: #fff;
			}
		}
	}
}
.images-data-row {
	.card-bottom-section {
		.dropdown {
			svg {
				fill: #fff;
			}
		}
	}
}
.cover-shadow::after {
	content: '';
	position: absolute;
	height: 75%;
	width: 100%;
	bottom: -70px;
	left: 0;
	right: 0;
	background: linear-gradient(transparent, #fff 90%, transparent);
}

.cover-shadow::after {
	@include dark-mode {
		background: linear-gradient(transparent, #393939 90%, transparent);
	}
}

.images-data-row {
	display: flex;
	gap: 15px;
	.row-item::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}
	.row-item.no-shadow::after {
		background-color: transparent;
	}
	.card-bottom-section {
		padding-bottom: 10px;
	}
}
.row-item {
	height: 250px;
	width: 200px;
	flex-shrink: 0;
	overflow: hidden;
	img {
		object-fit: cover;
	}
	border-radius: 16px;
}
.data-row {
	display: flex;
	gap: 15px;
}
.products-data-row,
.images-data-row {
	display: flex;
	gap: 15px;
	.row-item::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}
	.row-item.no-shadow::after {
		background-color: transparent;
	}
	.card-bottom-section {
		padding-bottom: 10px;
	}
}
.row-item {
	height: 250px;
	width: 200px;
	flex-shrink: 0;
	overflow: hidden;
	img {
		object-fit: cover;
	}
	border-radius: 16px;
}

p.uppercase {
	margin-bottom: 0;
}

.workflow-output-wrapper {
	padding: 0 8px;
	.right-section {
		margin-bottom: 100px;
	}
	.workflow-output-card-container {
		// gap: 4px;
		max-height: 680px;
		overflow-y: scroll;
	}
	.workflow-output-card {
		@media (max-width: 992px) {
			width: 50%;
		}
		.card {
			overflow: hidden;
			background-color: var(--bs-gray-card);
			// border: none !important;
		}
		.card.dark::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 50%;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				to top,
				rgba(30, 30, 30, 1),
				rgba(30, 30, 30, 0.5),
				rgba(30, 30, 30, 0.1)
			);
		}
	}
	.workflow-output-card {
		position: relative;
		flex-shrink: 0;
		@media (max-width: 600px) {
			width: 100%;
			height: 320px;
			margin-bottom: 10px;
		}
		@media (min-width: 992px) {
			width: 248px;
			height: 320px;
		}
	}
	.output-detail {
		margin-bottom: 30px;
		height: 92%;
		overflow: hidden;
	}
	.output-details-wrapper {
		overflow-y: scroll;
		.output-detail {
			margin-bottom: 0;
			height: auto;
			overflow: visible;
		}
	}
	.workflow-output-preview-card {
		height: 887px !important;
		overflow: hidden;
		position: relative;

		.card {
			background-color: var(--bs-gray-card);
			border: none !important;
			overflow: hidden;
		}
		.card-footer {
			background: transparent !important;
			border: none !important;
			z-index: 30;
			overflow: hidden;
			border-radius: 0 0 20px 20px;
		}
		.card-footer.dark {
			::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 50%;
				bottom: -20%;
				z-index: -1;
				left: 0;
				background-image: linear-gradient(
					to top,
					rgb(30, 30, 30, 0.1),
					rgba(30, 30, 30, 0.5),
					rgba(30, 30, 30, 0)
				);
			}
		}
		.btn-img {
			width: 30px;
			height: 30px;
		}
	}
	.divider {
		border-top: 2px solid var(--bs-divider);
	}
}

.fixed-menu {
	position: absolute;
	bottom: 80px;
	right: 0;
	z-index: 50;
	.main-menu {
		width: 60px;
		height: 60px;
		background: #000;
		border-radius: 50%;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(0.8);
		svg {
			fill: #fff;
		}
		img {
			width: 30px;
			height: 30px;
		}
	}
}

.output-tabs {
	padding: 10px 0;
	.tab-item {
		position: relative;
	}
	.tab-item::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 1px;
		width: 100%;
		background-color: var(--bs-primary);
		border-radius: 5px;
		display: none;
	}
	.tab-item.active::after {
		display: block;
	}
}

.log-tab {
	font-family: 'Fira Code', monospace;
}

.image-container {
	position: relative;
	width: 100%;
	padding-top: calc(2 / 3 * 100%);
	overflow: hidden;
}

.responsive-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.data-grid {
	// .row-item.img-card {
	// 	@media (min-width: 768px) {
	// 		width: 49%;
	// 		max-height: 300px;
	// 		height: auto;
	// 	}
	// 	.card-body-section {
	// 		height: auto;
	// 	}
	// }
	.header-avatar {
		.avatar-container {
			height: 32px;
			width: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			// padding: 3px;
		}
		.avatar {
			height: 90%;
			width: 90%;
			object-fit: contain;
		}
		.avatar:nth-last-child(1) {
			margin-left: 0px !important;
		}
	}
	.row-item {
		padding-bottom: 10px;
		overflow-y: hidden;
		@media (min-width: 768px) {
			width: 49%;
			max-height: 300px;
			height: 300px;
		}
		.card-head-section {
			min-height: 48px;
		}
		.card-body-section {
			height: calc(300px - 48px);
		}
		.card-title-text {
			// line-height: 1;
		}
		.small-sub-title {
			color: 'rgba(0, 0, 0, 0.4)';
			font-size: 12px;
		}
	}
	.image-grid-container,
	.text-container {
		display: flex;
		align-items: flex-end;
	}
	.video-card {
		padding-top: 56.25%;
		border-radius: 16px;
		background: black;
		.video-player {
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 16;
		}
		video {
			object-fit: cover;
		}
	}
}

// animations

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.slide-in-right-reverse {
	-webkit-animation: slide-in-right-reverse 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
	animation: slide-in-right-reverse 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
}

@-webkit-keyframes slide-in-right-reverse {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right-reverse {
	0% {
		-webkit-transform: translateX(1000px);
		transform: translateX(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
.border-transparent {
	border: 1px solid transparent;
}
.group-receiver {
	.avatar-wrapper {
		width: 30px;
		height: 30px;
		background-color: #02a67e;
		border-radius: 2px;
	}
}
.group-sender {
	.avatar-wrapper {
		width: 30px;
		height: 30px;
		background-color: var(--bs-primary);
		border-radius: 2px;
	}
}
.group-receiver {
	background-color: var(--bs-chat-receiver-bg);
}

.chat-input-container {
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	border-radius: 0.75rem;
	max-height: 200px;
	textarea {
		max-height: 200px;
		border: none;
		outline: none;
		resize: none;
		overflow-y: auto;
		color: #000;
	}
}
