.terminal-container,
.log-tab {
	width: 100%;
	height: 100%;
}
.terminal-container.light {
	blockquote {
		pre {
			margin-bottom: 0;
			code {
				color: #a0a8ba;
			}
		}
	}
}
// #e06c75 -red ,#90a959 - green
.terminal-container.dark {
	blockquote {
		pre {
			margin-bottom: 0;
			code {
				color: #5c6370;
			}
		}
	}
}
.terminal-container {
	.index_preWhiteSpace__7KwuG {
		color: #90a959;
	}
}
.timeline-cover {
	height: 32px;
	width: 100px;
	background-color: #2f3134;
	border-radius: 5px;
}

.audio-cover {
	height: 32px;
	width: 100px;
	background-color: #2f3134;
	border-radius: 5px;
}
