.sheet-modal {
	background-color: rgb(0, 0, 0);
	height: 100%;
	width: 100%;
}
.sheet-modal-header.parent-sheet-header {
	// transform: translateY(25px);
	height: 52px;
}
.icon-color {
	color: var(--bs-heading-color);
}
.dark-muted-text {
	color: var(--bs-dark-muted-text) !important;
}
.react-modal-sheet-container,
.react-modal-sheet-header {
	border-top-right-radius: 26px !important;
	border-top-left-radius: 26px !important;
}
.react-modal-sheet-header {
	height: 20px !important;
	z-index: 10;
}
.parent-sheet {
	.parent-sheet-header {
		background-color: var(--bs-modal-sheet-bg);
		border-top-right-radius: 24px !important;
		border-top-left-radius: 24px !important;
	}
	.parent-sheet-content {
		background-color: var(--bs-modal-sheet-bg);
	}

	.parent-sheet-header.bg-black {
		background-color: rgba(15, 15, 15, 1) !important;
		border-top-right-radius: 24px !important;
		border-top-left-radius: 24px !important;
	}
	.parent-sheet-content.bg-black {
		background-color: rgba(15, 15, 15, 1) !important;
	}
}

.top-btn-wrapper {
	background-color: var(--bs-modal-sheet-bg);
	position: sticky;
	top: 0;
	padding: 8px;
	@media (min-width: 600px) {
		transform: translateY(-20px);
	}
	z-index: 200;
}
.top-btn-wrapper.no-transform {
	transform: translateY(0px);
}
.child-sheet {
	.child-sheet-header {
		background-color: var(--bs-child-modal-sheet-bg);
		border-top-right-radius: 24px !important;
		border-top-left-radius: 24px !important;
		transform: translateY(0) !important;
	}
	.child-sheet-content {
		background-color: var(--bs-child-modal-sheet-bg);
	}
}

.main-node {
	background-color: var(--bs-main-node-bg) !important;
	color: var(--bs-main-node-color) !important;
	border: 1px solid var(--bs-main-node-border-color) !important;
}
.empty-node {
	border: none !important;
	outline: none !important;
	background: transparent !important;
	pointer-events: none !important;
	// width: 0px !important;
	// height: 0px !important;
	// padding: 0px !important;
	.react-flow__handle.connectionindicator {
		pointer-events: none !important;
	}
}
.empty-node:hover {
	box-shadow: none !important;
}

.sub-node {
	.react-flow__handle {
		background: #4d69fa;
	}
}
.custom-node {
	width: 250px !important;
	background-color: #fff !important;
	// box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1) !important;
	border: none !important;
	padding: 0 !important;
	border: 1px solid #eaeaea !important;
	border-radius: 1rem !important;
	.card {
		border-radius: 1rem !important;
		box-shadow: none !important;
	}
	.card-header {
		border-bottom: 1px solid #eaeaea !important;
		min-height: 3rem;
	}
	.card-body {
		min-height: 3rem;
	}
	p {
		text-align: left !important;
	}
	.text-ellipsis-50 {
		max-height: 55px;
		overflow: hidden;
	}
}
.main-node {
	background-color: var(--bs-main-node-bg) !important;
	color: var(--bs-main-node-color) !important;
	// border: 1px solid var(--bs-main-node-border-color) !important;
	border: 1px solid #eaeaea !important;
	border-radius: 1rem !important;
}
.output-node {
	width: 600px !important;
}
.uppercase-title {
	text-transform: uppercase;
	// font-size: 16px;
	color: var(--bs-uppercase-title);
}
.grey-text {
	color: var(--bs-uppercase-title);
}
.custom-popover-light {
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	padding: 10px;
	bottom: -50px;
	left: 50%;
	transform: translate(-50%);
	p {
		white-space: nowrap;
		color: #1f2128;
	}
	.arrow {
		border-color: transparent;
		border-style: solid;
		display: block;
		height: 0;
		position: absolute;
		width: 0;
		border-width: 11px;
		border-bottom-color: rgb(238 238 238);
		border-top-width: 0;
		top: -10px;
		left: 50%;
		transform: translate(-50%);
	}
	.arrow:after {
		border-color: transparent;
		border-style: solid;
		display: block;
		height: 0;
		position: absolute;
		width: 0;
	}
}

.filter-menu {
	ul {
		padding: 0;
		li {
			list-style-type: none;
		}
	}
	.menu-item {
		font-weight: 550;
	}
}

.black-text {
	color: var(--bs-black-text);
}
//Card
.grey-card {
	background-color: var(--bs-grey-card-bg);
	border-radius: 8px !important;
	box-shadow: none !important;
	border: 1px solid var(--bs-grey-card-border-color);
}
.grey-card-form {
	background-color: var(--bs-grey-card-bg);
	border-radius: 24px !important;
	box-shadow: none !important;
	border: 1px solid var(--bs-grey-card-border-color);
}
//Wizard Modal header
.wizard-sheet-header {
	background-color: var(--bs-child-modal-sheet-bg);
}

.show-y-scroll {
	overflow-y: scroll;
}
.collaborator-menu {
	.btn {
		border-radius: 0 !important;
	}
	.avatar {
		transform: scale(0.8);
	}
}
.hide-btn-icon {
	svg {
		visibility: hidden;
	}
}

.pointers-none {
	pointer-events: none;
}
