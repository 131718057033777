.mission-asset-panel {
	.mission-asset-card {
		background-color: rgba(0, 0, 0, 0.06);
		border: 1px solid var(--bs-grey-card-border-color);
		box-shadow: none;
		@include dark-mode {
			background-color: $black-100;
			border: none;
			box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
		}
	}

	.black-card {
		background-color: #fff;
		@include dark-mode {
			background-color: #000;
		}
	}
	.row-item {
		height: fit-content !important;
		.card-body-section {
			height: fit-content !important;
			min-height: 110px;
		}
	}
}
.theme-gray-text {
	color: rgba(0, 0, 0, 0.4);
	@include dark-mode {
		color: $grey-40;
	}
}
.theme-light-text {
	color: $grey-40;
}
.mission-card {
	background-color: $white;
	box-shadow: none;
	border: 1px solid var(--bs-grey-card-border-color);

	@include dark-mode {
		background-color: $black-100;
		box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
		border: none;
	}
	border-radius: 8px;
	height: 415px !important;
	width: 320px;
	flex-shrink: 0;
	.bold-title {
		font-weight: 600;
		font-size: 14px;
	}
	.info-text {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.4);

		@include dark-mode {
			color: $grey-40;
		}
		line-height: 24px;
	}
	.title {
		color: #000;
		@include dark-mode {
			color: #fff;
		}
	}

	.main-content {
		height: 100px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.summary-card {
	height: 515px !important;
}
.s-20 {
	font-size: 20px;
}
.s-17 {
	font-size: 17px;
}
.s-16 {
	font-size: 16px;
}
.s-15 {
	font-size: 15px;
}
.add-icon {
	width: 32px;
	height: 32px;
	background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		background-color: $black-100;
	}
	transform: rotate(45deg);
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		fill: rgba(50, 50, 50, 0.75);
		@include dark-mode {
			fill: $grey-40;
		}
		transform: rotate(-45deg);
	}
}

.zoom-tools {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: fit-content;
	gap: 12px;
	border-radius: 40px;
	width: 34px;
	padding: 9px 10px;
	background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		background-color: rgba(255, 255, 255, 10%);
	}
	.zoom-action-btn {
		color: rgb(50, 50, 50, 0.75);
		@include dark-mode {
			color: $grey-40;
		}
		svg {
			fill: rgb(50, 50, 50, 0.75);
			width: 18px;
			height: 18px;
			@include dark-mode {
				fill: $grey-40;
			}
		}
		font-size: 12px;
	}
}
.zoom-container {
	gap: 50px;
}
.react-transform-wrapper {
	width: 100% !important;
}
.pointer-events-none {
	pointer-events: none;
}
.user-select-none {
	user-select: none;
}
//Sandbox view styles
.sandbox-container {
	img {
		height: 500px;
	}
}
.whiteboard-container {
	height: 80vh;
	z-index: 100;
}
.circular-menu {
	position: absolute;
	bottom: 0;
	right: 100px;
	width: 48px;
	height: 48px;
}
.circle-btn {
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgb(0, 0, 0);
	@include dark-mode {
		border: none;
	}
	img,
	svg {
		object-fit: cover;
	}
	svg {
		fill: $grey-40;
		width: 45px;
		height: 45px;
	}
}
.circle-btn.shadow {
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 15%), 0 0px 19px 0 rgba(255, 255, 255, 15%) !important;
}
#MissionBuilder {
	.modal-content {
		@include dark-mode {
			background-color: rgb(30, 30, 30) !important;
		}
	}
}
.step-modal {
	.row-item {
		height: -webkit-fit-content !important;
		height: -moz-fit-content !important;
		height: fit-content !important;
		min-height: 110px;

		.video-card {
			.card-body-section {
				height: 110px !important;
			}
		}
		width: 100%;
		@media (min-width: 768px) {
			width: 48.5%;
		}
		@media (min-width: 992px) {
			width: 23.5%;
		}
		.card {
			min-height: 110px;
		}
		.card-body-section {
			height: -webkit-fit-content !important;
			height: -moz-fit-content !important;
			height: fit-content !important;
			min-height: 110px;
		}
	}
	.active-row-item {
		color: #fff;
		.theme-gray-text {
			color: #fff;
		}
		svg {
			fill: #fff;
		}
	}
}

.step-modal-assets {
	.row-item {
		border-radius: 0;
	}
	.add-card {
		border-radius: 8px;
		background-color: rgb(52, 52, 52);
		height: 110px;
		flex-shrink: 0;
		overflow: hidden;
		width: 100%;
		color: #fff;
		fill: #fff;
		@media (min-width: 768px) {
			width: 48.5%;
		}
		@media (min-width: 992px) {
			width: 23.5%;
		}
		@include dark-mode {
			color: $grey-40;
			fill: $grey-40;
		}
	}
	.img-card,
	.material-card,
	.attachment-card,
	.video-card {
		border-radius: 8px;
		border: 1px solid var(--bs-grey-card-border-color);
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: rgb(52, 52, 52);
			border: none;
			box-shadow: 0 1.6rem 3rem rgba(0, 0, 0, 0.1);
		}
	}
}

.close-icon {
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.06);

	@include dark-mode {
		background-color: rgb(52, 52, 52);
	}
	padding: 5px;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 1.8rem;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.border-primary {
	border: 1px solid $primary;
}

.card-right-bottom-icon {
	position: absolute;
	right: 10px;
	bottom: 10px;
	fill: rgba(50, 50, 50, 0.75);
	@include dark-mode {
		fill: $grey-40;
	}
}

.material-card {
	.card-right-bottom-icon {
		transform: rotate(40deg);
	}
}

.toast-message {
	background-color: #0f0f0f99;
	position: absolute;
	color: #fff;
	border-radius: 6px;
	padding: 4px 8px;
	font-size: 9px;
	right: 0;
	bottom: 10px;
	z-index: 10;
}
//Collaborator view styles
.collaborator-view {
	.tabs-container {
		.active-tab {
			border-bottom: 1px solid $primary !important;
		}
	}
	.collaborator-row {
		padding: 12px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 10%);
		.collaborator-profile {
			.user-avatar {
				width: 50px;
				height: 50px;
				.first-letter {
					width: 50px;
					height: 50px;
				}
				img {
					width: 50px !important;
					height: 50px !important;
				}
			}
		}
	}
	input[type='checkbox'] {
		accent-color: rgb(59, 59, 59);
		transform: scale(1.2);
	}

	.search-container {
		display: flex;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.06);

		@include dark-mode {
			background-color: #0f0f0f99;
		}
		border-radius: 40px;
		padding: 4px 6px;
		position: relative;
		.search-icon {
			fill: #000;
			@include dark-mode {
				fill: $grey-40;
			}
			position: absolute;
			left: 5px;
			top: 50%;
			transform: translateY(-50%) scale(0.8);
		}
		.search-input {
			font-weight: 500;
			border: none;
			outline: none;
			background-color: transparent;
			color: #000;
			@include dark-mode {
				color: $grey-40;
			}
			width: 100%;
			padding-left: 25px;
		}
	}
}
.custom-styled-textarea {
	background-color: rgba(0, 0, 0, 0.06);
	color: #000;

	@include dark-mode {
		background-color: #0f0f0f99;
		color: #fff;
	}
	width: 100%;
	border-radius: 8px;
	padding: 6px;
	border: none;
	outline: none;
}
.custom-styled-textarea.select {
	.css-1dimb5e-singleValue {
		@include dark-mode {
			color: #fff !important;
		}
	}
	.css-13cymwt-control {
		@include dark-mode {
			background-color: #0f0f0f99;
		}
	}
	.css-t3ipsp-control {
		@include dark-mode {
			background-color: #0f0f0f99;
		}
	}
	.css-1nmdiq5-menu {
		@include dark-mode {
			background-color: #000;
		}
	}
	.css-d7l1ni-option {
		@include dark-mode {
			background-color: var(--bs-primary);
		}
	}
}
.custom-styled-textarea::placeholder {
	color: $grey-40;
}
.custom-styled-textarea:focus {
	border: 1px solid #fff;
}
.fixed-bottom-container {
	position: static;
	bottom: 0px;
	z-index: 100;
	background: #fff;
	@include dark-mode {
		background: rgb(30, 30, 30);
	}
	width: 100%;
	@media (min-width: 992px) {
		width: 65%;
		position: fixed;
	}
	padding: 1.5rem 0;
}
.response-grid {
	display: grid;
	grid-template-columns: repeat(1, 1fr);

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: 992px) {
		grid-template-columns: repeat(3, 1fr);
	}
	.response-card {
		background-color: rgba(0, 0, 0, 0.06);
		border: 1px solid rgba(0, 0, 0, 0.06);
		@include dark-mode {
			background-color: rgb(52, 52, 52);
			border: 1px solid $black-100;
		}
		border-radius: 8px;
		height: 230px;
		width: 100%;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		cursor: pointer;
		.role-text,
		.content-text {
			color: rgb(50, 50, 50, 0.75);
			@include dark-mode {
				color: $grey-40;
			}
			font-weight: 500;
		}
		.card-top {
			height: 20%;
		}
		.card-bottom {
			height: 75%;
		}
	}
	.response-card.active {
		background-color: #fff;
		color: #000;
		border: 1px solid $black-100;
		.role-text {
			color: rgba(0, 0, 0, 50%);
		}
		.content-text {
			color: #000;
		}
	}
	.response-card:hover {
		background-color: #fff;
		color: #000;
		border: 1px solid $black-100;
	}
	.response-card:hover .role-text {
		color: rgba(0, 0, 0, 50%);
	}
	.response-card:hover .content-text {
		color: #000;
	}
}
.question-picker {
	background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		background-color: rgb(51, 51, 51);
	}
	border-radius: 8px;
	position: relative;
	cursor: pointer;
	.toggle-button {
		border: none;
		outline: none;
		width: 100%;
		background: rgba(0, 0, 0, 0.06) url('../../assets//img//icons//arrow-down.png') no-repeat
			calc(100% - 10px) center;

		@include dark-mode {
			background-color: rgb(51, 51, 51);
		}
		background-size: 18px;
		-moz-appearance: none; /* Firefox */
		-webkit-appearance: none; /* Safari and Chrome */
		appearance: none;
		cursor: pointer;
		padding: 20px 48px 20px 20px;
		border-radius: 8px;
	}
	ul {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		transform: translateY(100%);
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 100;
		border-radius: 8px;
		overflow: hidden;
		li {
			background: rgb(226, 226, 226);
			border-bottom: 1px solid #fff;

			@include dark-mode {
				background: rgb(0, 0, 0);
				// border: none;
			}
			padding: 20px;
		}
		li:hover {
			background: rgb(196, 196, 196);
			border-bottom: 1px solid #fff;
			@include dark-mode {
				background: rgb(30, 30, 30);
				// border: none;
			}
		}
	}
}
.question-picker-accordion {
	.accordion__item {
		border-radius: 8px;
		overflow: hidden;
	}
	.accordion__panel {
		padding-left: 0;
		padding-right: 0;
	}
	.accordion__button:before {
		position: absolute;
		right: 0;
		transform: translateY(50%) rotate(-45deg);
		@include dark-mode {
			border-bottom: 2px solid #7e7e7e;
			border-right: 2px solid #7e7e7e;
		}
	}
	.accordion__button {
		padding-right: 25px;
		@include dark-mode {
			background-color: rgb(51, 51, 51);
			color: #fff;
		}
	}
}
.step-modal-top-section {
	color: #000;
	@include dark-mode {
		color: #fff;
	}
}

.notification-dot {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #ff0000;
	position: absolute;
	right: -15px;
	top: 0;
	display: block;
}

.overflow-y-scroll {
	overflow-y: scroll;
}

.video-card {
	overflow: hidden;
	padding-top: 0 !important;
}

.dnd-container {
	height: 280px;
	width: 100%;
	border-radius: 8px;
	border: 1px solid rgba(255, 255, 255, 10%);
	background-color: rgba(0, 0, 0, 0.06);
	@include dark-mode {
		background-color: $black-100;
	}
}
.Toastify__toast {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 10px;
	border-radius: 16px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	@include dark-mode {
		border: none;
	}
}
.Toastify__toast-body {
	display: flex;
	align-items: center;
	gap: 10px;
}
.Toastify__toast-icon {
	width: 20px;
	height: 20px;
}
.step-modal {
	.Toastify__progress-bar {
		display: none;
	}
}
.alert-popup {
	overflow-x: hidden;
}
.page-flip-container {
	width: 396px;
	height: 560px;
}
.book {
	background-color: #fff;
	box-shadow: 0 0 2px 1px rgba(107, 107, 107, 0.25);
	@media (max-width: 600px) {
		transform: scale(0.6) !important;
	}
}
.page-container {
	background-color: #fff;
}
.--left::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	width: 226px;
	height: 560px;
	background: -webkit-linear-gradient(
		right,
		rgba(0, 0, 0, 0.15),
		rgba(255, 255, 255, 0.1) 16%,
		rgba(255, 255, 255, 0.1) 18%,
		transparent
	);
	transition: opacity 0.3s ease-out;
	opacity: 1;
}
.--right::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 317px;
	height: 560px;
	background: -webkit-linear-gradient(
		left,
		rgba(0, 0, 0, 0.17),
		rgba(255, 255, 255, 0.06) 15%,
		rgba(255, 255, 255, 0.06) 16%,
		transparent 100%
	);
	transition: opacity 0.3s ease-out;
	opacity: 1;
	z-index: 100;
}
.workflow-accordion {
	.accordion-button {
		font-weight: 600 !important;
		border-radius: 0 !important;
	}
	.accordion-button:not(.collapsed) {
		background-color: transparent !important;
		color: #000 !important;
	}
	.accordion,
	.accordion-button:not(.collapsed) {
		border-radius: 0 !important;
	}
	.accordion-button:hover {
		background-color: rgba(0, 0, 0, 0.06) !important;
	}
}
