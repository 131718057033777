//
// Components
// Settings - Tools - Generic - Elements - Objects - COMPONENTS - Vendors - Utilities
//

@import 'ConfigFile';

// Layout Components
@import 'layout/app';
@import 'layout/aside';
@import 'layout/brand';
@import 'layout/user';
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/subheader';
@import 'layout/navigation';
@import 'layout/content';
@import 'layout/page-wrapper';
@import 'layout/page';
@import 'layout/footer';

// Theme Component
@import 'svg-icon';
@import 'avatar';
@import 'chat';
@import 'wizard';

// Extra Component
@import 'extra/timeline';
@import 'extra/todo';

//  Bootstrap Components
@import 'bootstrap/tables';
@import 'bootstrap/forms';
@import 'bootstrap/buttons';
@import 'bootstrap/dropdown';
@import 'bootstrap/button-group';
@import 'bootstrap/nav';
@import 'bootstrap/card';
@import 'bootstrap/accordion';
@import 'bootstrap/breadcrumb';
@import 'bootstrap/pagination';
@import 'bootstrap/badge';
@import 'bootstrap/alert';
@import 'bootstrap/progress';
@import 'bootstrap/list_group';
@import 'bootstrap/close';
@import 'bootstrap/toasts';
@import 'bootstrap/modal';
@import 'bootstrap/popover';
@import 'bootstrap/carousel';
@import 'bootstrap/offcanvas';

// Custom Components
@import 'board';
@import '_wizard-modal.scss';
@import '_modules.scss';
@import '_datalake.scss';
@import '_workflow-output.scss';
@import '_mission-builder.scss';
@import '_collaborator-widget.scss';
@import '_present.scss';
@import '_marketing-dashboard.scss';
@import '_news-dashboard.scss';
@import '_event-dashboard.scss';

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@font-face {
	font-family: 'Ritual';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	font-size: 59pt;
	src: url(../../fonts/Ritual/OTF/Ritual-Regular.otf) format('woff2');
}
@font-face {
	font-family: 'Ritual';
	font-style: normal;
	font-weight: bold;
	font-display: swap;
	src: url(../../fonts/Ritual/OTF/Ritual-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'Ritual';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url(../../fonts/Ritual/OTF/Ritual-Semibold.otf) format('truetype');
}

html,
body {
	font-family: 'Ritual';
}
.product-description {
	.ql-editor {
		min-height: 10vh !important;
	}
}

.formselect__menu {
	z-index: 100 !important;
}

.remove-card-style {
	height: calc(100% - 1.5rem) !important;

	.shadow-3d-info::before,
	.shadow-3d-info::after {
		display: none !important;
	}

	.card.shadow-3d-info:nth-child(1) {
		box-shadow: none !important;
	}

	.alert-icon {
		display: none;
	}

	.alert-text {
		width: 100%;
	}

	.alert {
		.btn-close {
			display: none;
		}
	}

	.progress {
		background-color: #e7eef8 !important;
	}
}

.modern-design .header {
	margin: 0 !important;
	border-radius: none !important;
}
.uppercase {
	text-transform: uppercase;
}

@media (min-width: 992px) {
	.card-lg-small-width {
		width: 20%;
	}
}
.card-lg-small-width {
	flex-shrink: 0;
}
.fixed-avator {
	bottom: 30px;
	right: 10px;
	.avatar:nth-child(2) {
		transform: translate(-10px, 15px);
	}
	.avatar:nth-child(3) {
		transform: translate(-14px, 8px);
	}
	.avatar-more {
		transform: translate(-55px, 25px);
	}
}
.btn-grey {
	background-color: var(--bs-grey-bg) !important;
	color: var(--bs-black-text) !important;
	font-weight: normal !important;
	padding: 10px 15px;
	border-radius: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	// min-width: 100px;
	text-transform: capitalize;
}
.btn-grey:hover {
	border: 1px solid var(--bs-black-text);
	@include dark-mode {
		background-color: #fff;
		color: #000;
	}
}
.btn-dark-grey {
	background-color: var(--bs-dark-grey-bg);
	color: var(--bs-white);
	font-weight: normal !important;
	padding: 10px 15px;
	border-radius: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	// min-width: 100px;
	text-transform: capitalize;
}
.btn-dark-grey:hover {
	border: 1px solid var(--bs-white);
	color: var(--bs-white);
}
.btn-blue {
	background-color: var(--bs-blue);
	color: var(--bs-white);
	font-weight: normal !important;
	padding: 10px 15px;
	border-radius: 40px;
	// min-width: 100px;
	text-transform: capitalize;
}
.btn-blue:hover {
	border: 1px solid var(--bs-blue);
}
.btn {
	font-weight: normal !important;
	padding: 10px 15px;
	border-radius: 40px;
	text-transform: capitalize;
}
.rectangle-btn {
	font-weight: normal !important;
	padding: 6px 12px;
	text-transform: capitalize;
	border-radius: 8px;
}

.rectangle-grey-btn {
	font-weight: normal !important;
	padding: 6px 12px;
	text-transform: capitalize;
	border-radius: 8px;
	background-color: rgb(52, 52, 52);
	color: #fff;
	@include dark-mode {
		color: var(--bs-black-text);
	}
}
.rectangle-grey-btn:hover {
	border: 1px solid var(--bs-black-text);
	@include dark-mode {
		background-color: #fff;
		color: #000;
	}
}
.non-capitalize {
	text-transform: none;
}

.alert-light-grey {
	background-color: var(--bs-grey-card-bg);
	border: 1px solid var(--bs-grey-card-border-color);
}

.alert-light-red {
	background-color: var(--bs-red-card-bg);
	border: 1px solid var(--bs-red-text);
	.alert-text {
		color: var(--bs-red-text);
	}
	svg {
		fill: var(--bs-red-text);
	}
}

.aspect-ratio-three-halves {
	aspect-ratio: 3/2;
}
.objectfit-cover {
	object-fit: cover;
}
.objectfit-contain {
	object-fit: contain !important;
}

// .ellipsis-container {
// 	display: -webkit-box;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	-webkit-line-clamp: 3;
// 	-webkit-box-orient: vertical;
// 	max-height: 80;
// 	overflow: hidden;
// }

.chat-history {
	width: 260px;
	background: rgba(32, 33, 35);
	transform: translateX(0px);
	transition: all 0.2s linear;
	.chat-title {
		color: rgba(142, 142, 160, 1) !important;
		font-weight: 500;
		font-size: 0.75rem;
		line-height: 1rem;
	}
	.chat-text {
		font-size: 0.875rem;
		line-height: 1.25rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.chat-row {
		position: relative;
		overflow: hidden;
		border-radius: 0.375rem;
		margin-bottom: 5px;
		cursor: pointer;
		color: #fff;
		.gradient-cover {
			width: 2rem;
			background-image: linear-gradient(to left, #202123, rgba(32, 33, 35, 0));
			position: absolute;
			z-index: 10;
			right: 0;
			bottom: 0;
			top: 0;
		}
	}
	.chat-history-container {
		overflow-y: scroll;
		height: 100%;
	}
}
.chat-history.collapse-history {
	transform: translateX(-260px);
}
.chat-history.expand-history {
	transform: translateX(0px);
}
@media (max-width: 700px) {
	.chat-history {
		z-index: 100;
	}
}
@media (min-width: 768px) {
	.chat-history {
		transform: translateX(-260px);
		z-index: 0;
	}
}

.chat-btn {
	font-weight: normal !important;
	padding: 10px 15px;
	border: 1px solid hsla(0, 0%, 100%, 0.2);
	background-color: transparent;
	transition: all 0.2s linear;
	border-radius: 0.375rem;
	color: #fff;
	.material-icon {
		fill: #fff;
	}
}
.chat-btn:hover {
	background-color: hsla(240, 9%, 59%, 0.1);
}

.chat-row:hover {
	background-color: rgba(42, 43, 50, 1);
	.gradient-cover {
		background-image: linear-gradient(to left, #202123, rgba(42, 43, 50, 1));
	}
}
.chat-row.active {
	background-color: rgb(52, 53, 65);
	.gradient-cover {
		background-image: linear-gradient(to left, #343541, rgba(52, 53, 65, 0));
	}
}

.bg-green {
	background-color: #02a67e;
}

.chat-submit-btn {
	width: 20px;
	height: 20px;
	border-radius: 3px;
}
.chat-submit-btn.dark-mode {
	background-color: #02a67e;
	color: #fff;
}
.chat-submit-btn.light-mode {
	background-color: #fff;
	color: #dedee5;
}
.z-100 {
	z-index: 100;
}
.bg-cover {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: #fff;
	@include dark-mode {
		background-color: $dark-offcanvas-bg-color;
	}
}
.bg-cover.chat-offcanvas {
	background-color: #fff;
	@include dark-mode {
		background-color: #2f3339;
	}
}
.btn-transparent {
	background-color: transparent !important;
	border: none !important;
	color: #000 !important;
	@include dark-mode {
		color: #fff !important;
	}
}

.multiple-input-control {
	@media (max-width: 600px) {
		width: 80%;
	}
	@media (min-width: 768px) {
		width: 90%;
	}
	@media (min-width: 992px) {
		width: 93%;
	}
}

.popup-modal {
	background-color: var(--bs-modal-sheet-bg);
	border: 1px solid var(--bs-grey-card-border-color);
}
.custom-select {
	.css-13cymwt-control,
	.css-t3ipsp-control {
		@include dark-mode {
			background-color: #2b2e31 !important;
		}
	}
	.css-t3ipsp-control:hover {
		@include dark-mode {
			border-color: hsl(0, 0%, 70%) !important;
		}
	}
	.css-1nmdiq5-menu {
		@include dark-mode {
			color: #2b2e31 !important;
			background-color: #c8c8c8 !important;
		}
	}

	.css-166bipr-Input {
		@include dark-mode {
			color: white !important;
			background-color: #2b2e31 !important;
		}
	}

	.css-qbdosj-Input > text {
		@include dark-mode {
			color: white !important;
		}
	}
	.css-qbdosj-Input {
		@include dark-mode {
			color: white !important;
			background-color: #2b2e31 !important;
		}
	}

	.css-qbdosj-Input > text {
		@include dark-mode {
			color: white !important;
		}
	}
	.css-166bipr-Input {
		@include dark-mode {
			color: white !important;
			background-color: #2b2e31 !important;
		}
	}
	.css-18j15kc-multiValue,
	.css-1figg3y-MultiValueGeneric {
		@include dark-mode {
			color: white !important;
		}
		svg {
			@include dark-mode {
				fill: white !important;
			}
		}
	}
	.css-18j15kc-multiValue {
		@include dark-mode {
			background-color: black !important;
		}
	}
}
.image-icon-container {
	height: 20px;
	img {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}

.payment-grid {
	display: grid;
	grid-template-columns: 1fr;
	gap: 15px;
	row-gap: 0px;
	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: 992px) {
		grid-template-columns: repeat(3, 1fr);
	}
	.pay-card {
		@include dark-mode {
			border: 1px solid var(--bs-grey-card-border-color);
			background-color: #202123;
		}
	}
}

.small {
	font-size: 0.875em !important;
}
.w-0 {
	width: 0%;
}
.email-img {
	img {
		object-fit: cover;
		height: 100%;
	}
}
.input-option-btn {
	@include dark-mode {
		background-color: #202123 !important;
	}

	.svg-icon--material {
		@include dark-mode {
			color: white !important;
		}
	}
}

.imageBorder {
	border: 2px solid var(--bs-blue);
}

.custom-btn-secondary {
	color: #fff;
	background-color: #f74f75;
	border-color: #f5567b;
}

.custom-btn-secondary:hover {
	color: #fff;
	background-color: #f33461;
	border-color: rgb(245, 86, 142);
}

.custom-btn-secondary:focus,
.custom-btn-secondary.focus {
	box-shadow: 0 0 0 0.2rem #f33e68;
}

.custom-btn-secondary.disabled,
.custom-btn-secondary:disabled {
	color: #fff;
	background-color: #f74f75;
	border-color: #f74f75;
}

.custom-btn-secondary:not(:disabled):not(.disabled):active,
.custom-btn-secondary:not(:disabled):not(.disabled).active {
	color: #fff;
	background-color: #f74f75;
	border-color: #f5567b;
}

.show > .custom-btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #f74f75;
	border-color: #f74f75;
}

.custom-btn-secondary:not(:disabled):not(.disabled):active:focus,
.custom-btn-secondary:not(:disabled):not(.disabled).active:focus {
	box-shadow: 0 0 0 0.1rem #f74f75;
}

.show > .custom-btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(247, 79, 117, 0.5);
}

/* Webkit (Chrome, Safari, newer versions of Opera) */
/* The main scrollbar container */
::-webkit-scrollbar,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		width: 12px; /* Width of the scrollbar */
	}
}

/* The scrollbar track */
::-webkit-scrollbar-track,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		background-color: #343a40; /* Background color of the track */
	}
}

/* The scrollbar thumb */
::-webkit-scrollbar-thumb,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		background-color: #000; /* Color of the thumb */
		border-radius: 6px; /* Rounded corners */
	}
}

/* Microsoft Edge */
/* The main scrollbar container */
::-ms-scrollbar,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		width: 12px; /* Width of the scrollbar */
	}
}

/* The scrollbar track */
::-ms-scrollbar-track,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		background-color: #343a40; /* Background color of the track */
	}
}

/* The scrollbar thumb */
::-ms-scrollbar-thumb,
:root,
[data-bs-theme='dark'] {
	@include dark-mode {
		background-color: #000; /* Color of the thumb */
		border-radius: 6px; /* Rounded corners */
	}
}

.spin-btn {
	@include dark-mode {
		color: white !important;
	}
}

.timeline-editor {
	width: 100% !important;
	height: 200px !important;
}

#MissionBuilder {
	.card {
		overflow: visible;
		.dropdown-menu {
			transform: scale(0.8);
		}
	}
}

.image-grid {
	display: flex;
	flex-wrap: wrap;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 14px;
}

.image-grid-item {
	// flex: 0 0 auto;
	flex: 0 0 calc(33.3333% - 16px);
	display: grid;
	position: relative;
	overflow: hidden;
	// overflow-x: auto;
	border-radius: 8px;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}

.image-grid-item img {
	width: 60px;
	height: 60px;
	object-fit: cover;
}

.image-grid-item:hover {
	transform: scale(1.1);
}

.multi-image-grid {
	display: grid;
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}

.multi-image-grid:hover {
	transform: scale(1.1);
}

.multi-image-grid img {
	width: 70px;
	height: 70px;
	object-fit: cover;
}

.imageSkeletonLoader {
	width: 70px;
	height: 70px;
	border-radius: 5px;
	background: rgba(130, 130, 130, 0.2);
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(8%, rgba(130, 130, 130, 0.2)),
		color-stop(18%, rgba(130, 130, 130, 0.3)),
		color-stop(33%, rgba(130, 130, 130, 0.2))
	);
	background: linear-gradient(
		to right,
		rgba(130, 130, 130, 0.2) 8%,
		rgba(130, 130, 130, 0.3) 18%,
		rgba(130, 130, 130, 0.2) 33%
	);
	background-size: 800px 100px;
	animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-squares {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}
