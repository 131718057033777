//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.bg-grey{
	background: #f2f2f2 !important;
} 

.swal2-container {
	z-index: 2000000000 !important;
  }  
  .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 50px !important;
    width: 20px !important;
}
.css-1ntpn9b { 
	@include dark-mode{
    color: rgb(255, 255, 255) !important;  
	}
}

.rotating-upload-icon {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  
  .rotating-circle {
	position: absolute;
	border: 3px solid #007bff; /* Circle color */
	border-top: 3px solid transparent;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }

  .custom-video-player {
	position: relative;
	max-width: 100%;
	overflow: hidden;
  }
  .react-easy-crop {
	width: 100%;
  }
  .custom-button {
	position: absolute;
	top: 5%;
	right: 0%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: none;
	cursor: pointer;
	z-index: 2; /* Ensure the button appears above the video */
  }